<template>
  <div>
    <div class="space-1" style="border-top: dotted var(--gray) 2px;margin: -5px -10px 0px -10px;"></div>

    <div class="row" style="margin-top: -5px;font-size: 0.8em;" @click="removePick(bet.match_id)">

      <div class="col-10 d-flex justify-content-start bold" style="padding: 10px 0 0 10px">

        <lazy-image
            class="lozad"
            v-bind:src="getSportIcon(bet.sport_id)"
            v-bind:alt="getSportAlt(bet.sport_id)"
            style="background-color: var(--black);height: 0.8em;border-radius: 50%; margin-right: 5px; margin-top: 5px;"/>

        <span style="color: var(--black);">{{ bet.competitor_1 }} - {{ bet.competitor_2 }}</span>

      </div>

      <span class="col-2 text-center betslip-txt d-flex justify-content-end bold" style="padding: 10px; color: #c00018;">X</span>

    </div>

    <div class="row" style="margin-top: -15px;">
      <div class="col-12 betslip-txt d-flex justify-content-start"
           style="padding: 2px 10px 0px 10px; font-size: 0.8em;">
        <span class="">Market : </span>
        <span class="betslip-txt bold">{{ bet.market_name }}</span>
      </div>
    </div>

    <div class="row" style="margin-top: -15px;">
      <div class="col-10 betslip-txt d-flex justify-content-start" style="padding: 10px; font-size: 0.8em;">
        <span class="">Your Pick : </span>
        <span class="betslip-txt bold">{{ bet.outcome_name }}</span>
      </div>

      <span class="col-2 text-center betslip-txt d-flex justify-content-end bold" style="padding: 10px;font-size: 0.8em;">
        {{ bet.odds | formatOdds }}
      </span>

    </div>

    <div v-if="parseInt(bet.active) === 0" class="deactivated-odds">
      Outcome suspended
    </div>

    <div v-else-if="parseInt(bet.producer_status) === 0" class="deactivated-odds">
      Outcome suspended
    </div>

    <div v-else-if="parseInt(bet.status) === 1 " class="deactivated-odds">
      Outcome suspended
    </div>

    <div v-else-if="parseInt(bet.status) > 1 " class="deactivated-odds">
      Outcome Deactivated
    </div>

    <div v-else-if="directionText" class="odds-change">
      {{ directionText }}
    </div>

  </div>

</template>

<script>

export default {
  name: "Pick",
  props: {
    bet: {
      required: true
    }
  },
  methods: {
    init: function(){

      var vm = this;

      vm.previousOdds = this.bet.odds;

      vm.id = this.getOddID(this.bet.match_id,this.bet.market_id,this.bet.outcome_id,this.bet.specifier,'odd');
      this.EventBus.$on('odds:'+vm.id,function(payload){

        var hasChanged = false;

        if(parseFloat(payload.odds) !== parseFloat(vm.bet.odds)) {

          hasChanged = true;
        }

        if(parseInt(vm.bet.active) !== parseInt(payload.active)) {

          hasChanged = true;
        }

        if(parseInt(vm.bet.status) !== parseInt(payload.status)) {

          hasChanged = true;
        }

        if(hasChanged) {

          console.log(JSON.stringify(payload,undefined, 4));

          vm.bet.status = payload.status;
          vm.bet.active = payload.active;

          var oddsChangedPayload = {};

          oddsChangedPayload.id = vm.id;
          oddsChangedPayload.odds = payload.odds;
          oddsChangedPayload.status = payload.status;
          oddsChangedPayload.active = payload.active;
          oddsChangedPayload.previous_odds = payload.odds;

          console.log('current '+payload.odds+' previous '+vm.bet.odds);

          if (parseFloat(payload.odd) !== parseFloat(vm.bet.odds)) {

            vm.previousOdds = vm.bet.odds;
            vm.bet.odds = payload.odds;

            // odds changed
            if (parseFloat(payload.odds) > parseFloat(vm.previousOdds)) {

              var direction = 1

            } else {

              direction = 0

            }

            console.log('direction '+direction);

            var directionTxt = parseInt(direction) === 1 ? 'odds has gone up ' : 'odds has gone down ';
            directionTxt = directionTxt + ' from ' + vm.formatOdds(vm.previousOdds) + ' to ' + vm.bet.odd;
            vm.bet.odds_change_text = directionTxt;
            oddsChangedPayload.previous_odds = vm.previousOdds;
            console.log('directionTxt '+directionTxt);

          }

          vm.EventBus.$emit('odds:changed', oddsChangedPayload);

        }

      });

      this.EventBus.$on('producer:status',function(payload){

        //var producer_id = payload.producer_id;
        vm.bet.producer_status = payload.producer_status;

        //if(parseInt(producer_id) === 1 ) {

        //vm.bet.producer_status = producer_status;

        // }

      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    },
    getSportAlt: function (sport_id) {

      switch (parseInt(sport_id)) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }

      return ""
    },
  },
  filters: {

    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
  mounted: function () {

    this.init();

  },
  data: function () {

    return {
      id: '',
      statusClass: '',
      previousOdds: '',
      oddActive:'',
      producerStatus: '',
      locked: false,
    }
  },
  watch: {
    bet: function (){
      this.init();
    }
  },
  computed: {

    directionText: function (){

      return this.odds_change_text;

    }

  }
}
</script>